import React from "react";
import styled from "styled-components";
import { Text } from "basis";
import { COLOR } from "../theme";
import { Container } from "../";

import Description from "./Description/Description";
import { APPLE_GROUPS } from "../../core/constants";

const PlanType = styled.div`
  border: ${COLOR.MID_GREY} 1px solid;
  border-radius: 5px 5px 0 0;
  padding: 12px 15px;
`;

const PlanDescriptionContainer = styled.div`
  background: ${COLOR.SECONDARY_LIGHT_BLUE_15};
  border: none;
  padding: 16px;
`;

const Single = ({ title, promotion, order }) => {

  const hideDescription = APPLE_GROUPS.includes(order?.merchantGroup);

  return (
    <>
      <Container>
        <Text textStyle="heading5" margin="8 0 2">
          {title}
        </Text>
        <Text textStyle="heading6" color="grey.t75" margin="0 0 4">
          Review the Interest Free Payment Plan available below
        </Text>
      </Container>

      <Container margin="0">
        <PlanType padding="4" bg="white">
          <Text textStyle="body2">
            <strong>{promotion?.label}</strong>
          </Text>
        </PlanType>

        {!hideDescription && promotion?.label && order?.amount && (
          <PlanDescriptionContainer padding="4">
            <Description promotion={promotion} order={order} />
          </PlanDescriptionContainer>
        )}
      </Container>
    </>
  );
};

export default Single;
