import React, { useCallback, useState } from "react";
import { Text } from "basis";
import styled from "styled-components";

import { PROMO_SELECT_PLACEHOLDER } from "../../core/constants";

import { COLOR } from "../theme";
import { Container, Select } from "../";

import Description from "./Description/Description";
import { APPLE_GROUPS } from "../../core/constants";

const StyledSelect = styled.div`
  label {
    display: none;
  }

  > div:first-of-type {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      right: 60px;
      height: calc(100% - 1px);
      width: 1px;
      background: ${COLOR.MID_GREY};
      top: 1px;
    }
  }

  select {
    border: ${COLOR.MID_GREY} 1px solid;
    border-radius: 5px 5px 0 0;
    color: ${COLOR.DARK_GREY};
    margin-top: 1px;
    padding: 16px 60px 16px 16px;
    height: auto;
    white-space: pre-wrap;
    font-weight: 600;

    option {
      font-size: 14px;
      font-weight: 600;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;

const Multiple = ({
  promotions,
  selectedPromotion,
  loading,
  onChange,
  order,
  title,
  currentState=PROMO_SELECT_PLACEHOLDER
}) => {
  const [selectedPlan, selectPlan] = useState(PROMO_SELECT_PLACEHOLDER);
  const [placeholder, setPlaceholder] = useState(PROMO_SELECT_PLACEHOLDER);

  const hideDescription = APPLE_GROUPS.includes(order?.merchantGroup);

  const handlePromotionChange = useCallback(
    (_checked, newValue) => {
      if (newValue !== PROMO_SELECT_PLACEHOLDER) {
        setPlaceholder(null);
      }
      onChange(newValue);
      // currentState(newValue)
      selectPlan(newValue);
    },
    [onChange]
  );
  return (
    <>
      <Container>
        <Text textStyle="heading5" margin="8 0 2">
          {title}
        </Text>

        <Text color="grey.t75" margin="0 0 4">
          Select an Interest Free Payment Plan available below.
        </Text>
      </Container>

      <Container margin="0">
        <StyledSelect>
          {promotions && (
            <Select
              name="promotion"
              color="white"
              disabled={loading}
              options={promotions}
              placeholder={placeholder}
              onChange={handlePromotionChange}
            />
          )}

          {!hideDescription &&
            selectedPlan !== PROMO_SELECT_PLACEHOLDER &&
            order?.amount && (
              <Container bg="secondary.lightBlue.t15" padding="4">
                <Description promotion={selectedPromotion} order={order} />
              </Container>
            )}
        </StyledSelect>
      </Container>
    </>
  );
};

export default Multiple;
