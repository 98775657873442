import { Grid, Text, Button, Stack } from 'basis'
import React from 'react'
import styled from 'styled-components';
import { Container } from '../../../components';
import { applyGemUrl, appUrl, assetsBaseUrl } from '../../../core/config';


const Wrapper = styled.div`
margin: 24px 0;

button {
  cursor: pointer;
  width: 100%;
}
`;
const StyledImage = styled.img`
  height: ${({ isVertical }) => (isVertical ? "95px" : "75px")};
  margin-right: 5px;
  display: block;
`;
function TokenApplySection({ applyHeader, applyDescription, applyButtonText, applyCardDescription, applyApprovedHeader, applyButton }) {
    let applyQueryParams
    if(appUrl==='https://checkout.latitudefinancial.com'){
        applyQueryParams=`?merchantID=000422456&channel=Online&source=3&stream=Downstream&gemid2=lfs-checkout:checkout:owuponline:apply-now:gem-visa`
    }else{
        applyQueryParams=`?merchantID=000422456&channel=Online&source=3&stream=Downstream&gemid2=lfs-checkout:checkout-retail:owdponlinetest:apply-now:gem-visa`
    }
    return (
        <Grid.Item colSpan="all" colSpan-md="0-3" colSpan-lg="0-4">
            <Container bg="white" margin="4 0" padding="10 6" maxWidth={700}>
                <Stack gap="4">
                    <Text align="left" textStyle="heading4">
                        {applyHeader}
                    </Text>
                    <Grid >
                        <Grid.Item colSpan="0-1" colSpan-md="0-4" colSpan-lg="0-4">
                            <Container bg="white" margin="0 2 0 0" >
                                <StyledImage
                                    src={`${assetsBaseUrl}/latitude/acceptedCard/GEMVisa_V1.png`}
                                    isVertical
                                />
                            </Container>
                        </Grid.Item>

                        <Grid.Item colSpan-md="5-11" colSpan-lg="5-11">
                            <Stack gap="4">
                                <Stack>
                                    <Text textStyle="body2" align="left">
                                        {applyDescription}
                                    </Text>
                                </Stack>
                                <Text align="left" textStyle="heading6">
                                    {applyButtonText}
                                </Text>

                                <Stack>
                                    <Text textStyle="body2" align="left">
                                        {applyCardDescription}
                                    </Text>
                                </Stack>
                                <Text align="left" textStyle="heading6">
                                    {applyApprovedHeader}
                                </Text>

                                <Wrapper>
                                    <Button
                                        color="green"
                                        showButton={true}
                                        onClick={() => {
                                            window.open(
                                                `${applyGemUrl}${applyQueryParams}`,
                                                '_blank'
                                            );
                                        }}
                                    >
                                        {applyButton}
                                    </Button>
                                </Wrapper>
                            </Stack>
                        </Grid.Item >
                    </Grid>
                </Stack>

            </Container>
        </Grid.Item>
    )
}

export default TokenApplySection