import React from "react";

import Single from "./Single";
import Multiple from "./Multiple";

const Promotions = ({
  title,
  promotions = [],
  selectedPromotion,
  loading,
  onChange,
  order,
  currentState
}) => {
  if (promotions?.length === 1) {
    return <Single title={title} promotion={selectedPromotion} order={order} />;
  }
  return (
    <Multiple
      title={title}
      promotions={promotions}
      selectedPromotion={selectedPromotion}
      loading={loading}
      onChange={onChange}
      currentState={currentState}
      order={order}
    />
  );
};

export default Promotions;
