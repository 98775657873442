import React from "react";
import styled from "styled-components";

import { Icon } from "../../../components";
import { COLOR } from "../../../components/theme";

const StyledLink = styled.a`
  display: flex;
  color: ${COLOR.HIGHLIGHT_BLUE};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  align-self: center;
  margin: 8px 0 22px 0;

  div {
    margin-right: 10px;
  }
`;

export const BackIconLink = ({ onClick, title }) => (
  <StyledLink onClick={onClick} margin="4 0 0 4">
    <Icon type="iconArrow" size="16px" color={COLOR.HIGHLIGHT_BLUE} />
    {title}
  </StyledLink>
);
